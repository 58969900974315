import { ImageFormat, ImageSizes } from '@hubcms/domain-images';
import { PerViewport } from '@hubcms/domain-styling';
import { ArticleTeaserData, DefaultTeaserOrientation, PlaceholderTeaserData, TeaserSizes } from '@hubcms/domain-teaser';
import { GDefaultTeaser, GTextOnImageTeaser, TGridProps } from '@hubcms/domain-teaser-grid';
import { TrackingData } from '@hubcms/domain-tracking';

export const createTeaserForGroup = ({
  gridProps,
  hasDateTime = false,
  hasInset,
  hasIntro = false,
  hasLabel = true,
  headerLevel = 3,
  imageFormat,
  imageSizes,
  isHighlighted,
  isPriority,
  orientation = 'horizontal',
  size,
  teaserData,
  teaserType,
  theme,
  trackingData,
}: {
  gridProps: TGridProps;
  hasDateTime?: boolean;
  hasInset?: boolean | PerViewport<boolean>;
  hasIntro?: boolean | PerViewport<boolean>;
  hasLabel?: boolean;
  headerLevel?: number;
  imageFormat?: ImageFormat | PerViewport<ImageFormat>;
  imageSizes: ImageSizes;
  isHighlighted?: boolean;
  isPriority?: boolean;
  orientation?: DefaultTeaserOrientation | PerViewport<DefaultTeaserOrientation>;
  size: TeaserSizes;
  teaserData: ArticleTeaserData | PlaceholderTeaserData;
  teaserType: 'default-teaser' | 'text-on-image-teaser';
  theme?: string;
  trackingData?: TrackingData;
}): GDefaultTeaser | GTextOnImageTeaser => {
  const commonData = {
    hasDateTime,
    hasInset,
    hasIntro,
    hasLabel,
    headerLevel,
    imageSizes,
    isHighlighted,
    isPriority,
    size,
    teaserData,
    theme,
    trackingData,
  };

  if (teaserType === 'text-on-image-teaser') {
    return {
      type: teaserType,
      gridProps,
      data: {
        ...commonData,
        imageFormat,
      } as GTextOnImageTeaser['data'],
    };
  }

  return {
    type: teaserType,
    gridProps,
    data: {
      ...commonData,
      orientation,
    } as GDefaultTeaser['data'],
  };
};
