import { RegularGroupOptions } from '@hubcms/domain-storyblock';

export function getTeaserType<T extends RegularGroupOptions['teaserType']>(teaserType: string): T {
  if (
    teaserType === 'default' ||
    teaserType === 'textOnImageOneOne' ||
    teaserType === 'textOnImageFourFive' ||
    teaserType === 'textOnImage' // Remove this line in CW-2612
  ) {
    return teaserType as T;
  }
  return 'default' as T;
}
